import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { CONTEXT_ROLES } from 'config/permissions/context-roles.types';

import { ContextDetailsProps, ContextDetailsInfoProps } from './ContextDetails.types';
import * as S from './contextDetails.styles';

const ContextDetailsInfo = ({ name, id, role }: ContextDetailsInfoProps) => {
  const { translate } = useTranslations('component.contextSwitcher');
  const roleTranslation = role === CONTEXT_ROLES.MAIN_USER ? 'administrator' : 'collaborator';

  return (
    <>
      <S.ContextName>{name}</S.ContextName>
      <S.ContextEntity>
        {translate('nr')} {id} | {translate(roleTranslation)}
      </S.ContextEntity>
    </>
  );
};

export const ContextDetails = ({ name, id, role, isTopLevel }: ContextDetailsProps) => {
  return isTopLevel ? (
    <S.Wrapper>
      <ContextDetailsInfo name={name} id={id} role={role} />
    </S.Wrapper>
  ) : (
    <div>
      <ContextDetailsInfo name={name} id={id} role={role} />
    </div>
  );
};
