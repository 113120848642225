import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBillingCustomersQueryVariables = Types.Exact<{
  accountId: Types.Scalars['String'];
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetBillingCustomersQuery = (
  { __typename?: 'Query' }
  & { billingCustomers?: Types.Maybe<(
    { __typename?: 'BillingCustomerList' }
    & { items: Array<(
      { __typename?: 'BillingCustomer' }
      & Pick<Types.BillingCustomer, 'id' | 'type' | 'name' | 'role'>
    )> }
  )> }
);


export const GetBillingCustomersDocument = gql`
    query getBillingCustomers($accountId: String!, $query: String) {
  billingCustomers(accountId: $accountId, query: $query) {
    items {
      id
      type
      name
      role
    }
  }
}
    `;

/**
 * __useGetBillingCustomersQuery__
 *
 * To run a query within a React component, call `useGetBillingCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingCustomersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetBillingCustomersQuery(baseOptions: Apollo.QueryHookOptions<GetBillingCustomersQuery, GetBillingCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingCustomersQuery, GetBillingCustomersQueryVariables>(GetBillingCustomersDocument, options);
      }
export function useGetBillingCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingCustomersQuery, GetBillingCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingCustomersQuery, GetBillingCustomersQueryVariables>(GetBillingCustomersDocument, options);
        }
export type GetBillingCustomersQueryHookResult = ReturnType<typeof useGetBillingCustomersQuery>;
export type GetBillingCustomersLazyQueryHookResult = ReturnType<typeof useGetBillingCustomersLazyQuery>;
export type GetBillingCustomersQueryResult = Apollo.QueryResult<GetBillingCustomersQuery, GetBillingCustomersQueryVariables>;