import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import { ThemeProvider, Themes } from '@vodafoneziggo/sandwich';
import GlobalThemeStyle from '@vodafoneziggo/sandwich/theme/global';
import * as Sentry from '@sentry/react';

import { GraphQLProvider } from 'context/graphql/graphql.context';

import { history } from 'utils/history';
import { FrontendConfigsContainer } from 'containers/Other/FrontendConfigs.container';
import GridThemeProvider from 'styles/GridThemeProvider';
import { GlobalAppStyle } from 'styles/GlobalAppStyle';
import Routes from 'routes/routes';

/**
 * In order to redirect navigation to the legacy portal we have to do a full page reload.
 * The legacy portal will be served and take over the routing from there.
 */
const FullPageReload = () => {
  window.location.reload();

  return null;
};

/**
 * The invoice list page is requested with a payment token, lets redirect to the payment result page.
 * With window.top we can break out of the iframe in case that hasn't happened yet (like in local development or the creditcard flow).
 */
const handlePaymentRedirect = (invoiceListPathMatch: RegExpExecArray, paymentToken: string | number) => {
  if (!window || !window.top) {
    return;
  }

  window.top.location.assign(
    `/zakelijk/my/v2/${invoiceListPathMatch[1]}/${
      invoiceListPathMatch[2]
    }/rekeningen/betaald?token=${encodeURIComponent(paymentToken)}`
  );
};

/**
 * Catch returning from the payment and redirect to the payment result page.
 */
const redirectIncomingPaymentResult = (): boolean => {
  const invoiceListPathMatch = /\/my\/v2\/([^/]*)\/([^/]*)\/rekeningen\/overzicht/.exec(window.location.pathname);
  const locationSearch = new URLSearchParams(window.location.search);
  const paymentToken = locationSearch.get('token');

  if (!invoiceListPathMatch || !paymentToken) {
    return false;
  }

  handlePaymentRedirect(invoiceListPathMatch, paymentToken);

  return true;
};

const App = () => {
  // Do not start rendering the app in case the user is being redirected
  if (redirectIncomingPaymentResult()) {
    return null;
  }

  return (
    <ErrorBoundary>
      <FrontendConfigsContainer.Provider>
        <ThemeProvider theme={Themes.VODAFONE_WS10}>
          <GridThemeProvider>
            <GlobalThemeStyle />
            <GlobalAppStyle />
            <ErrorBoundary isModal>
              <GraphQLProvider>
                <Router history={history}>
                  <Switch>
                    <Route path="/v2">
                      <Routes path="/v2" />
                    </Route>
                    <Route>
                      <FullPageReload />
                    </Route>
                  </Switch>
                </Router>
              </GraphQLProvider>
            </ErrorBoundary>
          </GridThemeProvider>
        </ThemeProvider>
      </FrontendConfigsContainer.Provider>
    </ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
