import { useEffect } from 'react';
import ChatModule from '@vodafonepub/chat/src/chat';
import { History } from 'history';

interface VfChatElement extends Element {
  newPage: (section: string) => void;
  setChatOffered: () => void;
  setChatClicked: () => void;
}

/**
 * Instantiates the Chat Element, which scans the DOM for the existence of a '<vf-chat'> tag,
 * and if found, loads the chat provider tag in the DOM. A setTimeout is added to ensure
 * the tag is rendered to the DOM before instantiating the class.
 */
export const useInitializeChat = (history: History) => {
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-new
      new ChatModule();
    }, 500);

    const unlistenHistory = history.listen(() => {
      const chatElm: VfChatElement | null = document.querySelector('vf-chat');

      if (chatElm?.newPage) {
        const section = chatElm.getAttribute('section');

        chatElm.newPage(section || '');
      }
    });

    return () => {
      unlistenHistory();
    };
  }, [history]);
};
