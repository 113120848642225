import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useKahunaApi = () => {
  const [token, setToken] = useState<string>('');

  const determineHost = () => {
    const origin = location.origin;

    if (origin.includes('kahuna-src.qnh.nl:8888')) {
      return 'https://kahuna-src.qnh.nl:8888';
    } else if (!/\.vodafone\.nl$/.test(origin)) {
      return 'https://www.vodafone.nl';
    }

    return origin;
  };

  return {
    token,
    determineHost,
    setToken,
  };
};

export const KahunaApiContainer = createContainer(useKahunaApi);
