import React, { useState, useEffect, useContext, useMemo } from 'react';
import { EnterpriseContextType, IEnterpriseContext, IUser } from '@vodafoneziggo/sandwich/models/user.model';
import * as Sandwich from '@vodafoneziggo/sandwich/generic/user';
import { AuthenticationState, AuthContext } from 'context/auth';
import { GraphQLContext } from 'context/graphql/graphql.context';
import { redirectToChooseContext, redirectToLegacy, redirectToMyConsumer } from 'utils/location';
import { wrapWithCancellation } from '@vodafoneziggo/sandwich';
import { UserProviderProps, IUserContext, getAuthenticatedUserArguments } from './userContext.types';
import { getAuthenticatedUser } from './user.context.util';
import { useLocationContextInfo } from './hooks/useLocationContextInfo';

export const UserContext = React.createContext<IUserContext>({
  mainUserBillingCustomers: undefined,
  state: { ...Sandwich.initialUserState, enterprise: { choosableContexts: [], contexts: [] } },
  setActiveContext: /* istanbul ignore next */ () => null,
});

export const UserProvider = ({ children }: UserProviderProps) => {
  const [activeContext, setActiveContext] = useState<IEnterpriseContext>();

  const authState = useContext(AuthContext);
  const graphQLContext = useContext(GraphQLContext);
  const locationContextInfo = useLocationContextInfo();
  const [user, setUser] = useState<IUser>();

  // STEP 1. fetch the authenticated-user
  useEffect(() => {
    if (authState !== AuthenticationState.AUTHENTICATED) return; // Wait to be authenticated according to OIDC
    const cancellation = wrapWithCancellation();

    const authenticatedUserArguments: getAuthenticatedUserArguments = {};

    if (locationContextInfo.contextType === EnterpriseContextType.ACCOUNT) {
      authenticatedUserArguments.accountId = locationContextInfo.contextId;
    } else if (locationContextInfo.contextType === EnterpriseContextType.CUSTOMER) {
      authenticatedUserArguments.billingCustomerId = locationContextInfo.contextId;
    }

    getAuthenticatedUser(authenticatedUserArguments).then(
      cancellation.wrapper((response) => {
        const responseUser = response.data as IUser;

        setUser(responseUser);
      })
    );

    // Return the cancellation cancel method as cleanup method. This will prevent unwanted
    // side effects when the promise resolves after an unmount
    return cancellation.cancel;
  }, [authState, locationContextInfo.contextId, locationContextInfo.contextType]);

  // TODO STEP 2. case 1: when no location (URL) info is available, select the default.
  // This will be needed when the /overzicht page is being build in React

  // STEP 2. case 2: when location (URL) info is available, and authenticated-user is fetched, select the correct context
  useEffect(() => {
    if (!user) return; // wait for STEP 1: first authenticated-user call
    if (!locationContextInfo) return; // No context info in the URL

    /**
     * Redirect the user his start page, can be used when something unexpected happens.
     * Defined inside the useEffect to prevent adding more variables to the dependency list
     */
    const redirectToSafety = () => {
      // TS and ESLint will not shut up when using optional chaining 🤷
      if (
        user &&
        user.enterprise &&
        user.enterprise.choosable_contexts &&
        user.enterprise.choosable_contexts.length > 1
      ) {
        redirectToChooseContext();

        return;
      }

      redirectToLegacy('/overzicht');
    };

    // The context from the location, should be found in the enterprise.contexts
    const matchedContext = user?.enterprise?.contexts?.find(
      (context) => context.type === locationContextInfo.contextType && context.id === locationContextInfo.contextId
    );

    if (matchedContext) {
      setActiveContext(matchedContext);

      return;
    }

    // If the context is not in the user info, the user needs to be fetched by using the specific account or billingCustomerId
    const authenticatedUserArguments: getAuthenticatedUserArguments = {};

    if (locationContextInfo.contextType === EnterpriseContextType.ACCOUNT) {
      authenticatedUserArguments.accountId = locationContextInfo.contextId;
    } else if (locationContextInfo.contextType === EnterpriseContextType.CUSTOMER) {
      authenticatedUserArguments.billingCustomerId = locationContextInfo.contextId;
    } else {
      redirectToSafety();

      return;
    }

    const cancellation = wrapWithCancellation();

    // Context was not found in the first response, request user info using account_id
    // or billing_customer_id, depending on the context type in the location
    // This can happen, when the user has got multiple choosable contexts
    getAuthenticatedUser(authenticatedUserArguments)
      .then(
        cancellation.wrapper((response) => {
          const responseUser = response.data as IUser;

          if (!responseUser.enterprise.contexts?.length) {
            // No contexts in the detailed response, something is off
            redirectToSafety();
          }

          // This will trigger this useEffect again and try to find the activeContext in enterprise.contexts
          setUser(responseUser);
        })
      )
      .catch(
        cancellation.wrapper(() => {
          redirectToSafety();
        })
      );

    // Return the cancellation cancel method as cleanup method. This will prevent unwanted
    // side effects when the promise resolves after an unmount
    return cancellation.cancel;
  }, [user, locationContextInfo]); // These should be the only two dependencies for this effect

  /**
   * The choosable context are the actual accounts a user has got access to
   */
  const choosableContexts = useMemo(
    () => user?.enterprise?.choosable_contexts ?? [],
    [user?.enterprise?.choosable_contexts]
  );

  /**
   * The fetched contexts, are the contexts for the current active "choosable context"
   */
  const contexts = user?.enterprise?.contexts ?? [];

  /**
   * The total amount of billing customers underneath this user.
   */
  const totalBillingCustomers = user?.enterprise?.nr_billing_customers || 0;

  /**
   * We need to set the x-ctx-contact-id header on the graphQLContext
   * with the correct enterprise.contact_id property from our user
   */
  useEffect(() => {
    // Fallback to contact contact_id when no enterprise contact_id is available (for instance IWR might have this)
    let ctxContactId;

    if (user?.enterprise?.contact_id !== undefined) {
      ctxContactId = user?.enterprise?.contact_id;
    } else {
      ctxContactId = user?.contact?.contact_id;
    }

    if (!ctxContactId) return;

    graphQLContext.setHeader('x-ctx-contact-id', ctxContactId);
  }, [user?.enterprise?.contact_id, user?.contact?.contact_id, graphQLContext]);

  /**
   * When a user without choosableContexts is logged in, it's probably a consumer account, redirect them to the consumer portal
   */
  useEffect(() => {
    if (user && !choosableContexts.length) {
      redirectToMyConsumer();
    }
  }, [user, choosableContexts]);

  const state: IUserContext = {
    mainUserBillingCustomers: user?.enterprise?.nr_main_user_billing_customers,
    state: {
      ...Sandwich.initialUserState,
      user,
      enterprise: { choosableContexts, contexts, activeContext, totalBillingCustomers },
    },
    setActiveContext,
  };

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

UserProvider.displayName = 'UserProvider';
