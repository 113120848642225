import React, { useState } from 'react';
import { ILayoutContextState, LayoutProviderProps } from './layoutContext.types';

const initialLayoutState = { isPaymentMode: false, isPartialPayment: false };

/**
 * This context will provide the layout customization options, using it,
 * components can tweak the global layout of the page
 */
export const LayoutContext = React.createContext<ILayoutContextState>({
  state: initialLayoutState,
  setIsPaymentMode: () => null,
  setIsPartialPayment: () => null,
});

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [isPaymentMode, setIsPaymentMode] = useState(false);
  const [isPartialPayment, setIsPartialPayment] = useState(false);

  const state: ILayoutContextState = {
    state: {
      isPaymentMode,
      isPartialPayment,
    },
    setIsPaymentMode,
    setIsPartialPayment,
  };

  return <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>;
};
