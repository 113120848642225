import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RouterHistory } from '@sentry/react/types/reactrouter';

export const sentryConfig = (history: RouterHistory) => ({
  dsn: 'https://e0484133ca8546a495037adf44bf991b@o575421.ingest.sentry.io/5783924',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.1,
  environment: process.env.PIPELINE,
  release: `mvz-react@${process.env.npm_package_version}`,
});
