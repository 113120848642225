import React from 'react';
import { gridTheme } from '@vodafoneziggo/sandwich';
import { GridThemeProvider as BootstrapGridThemeProvider } from 'styled-bootstrap-grid';

interface Props {
  children: React.ReactNode;
}

const GridThemeProvider: React.FC<Props> = ({ children }) => {
  return (
    <BootstrapGridThemeProvider gridTheme={gridTheme}>
      <>{children}</>
    </BootstrapGridThemeProvider>
  );
};

export default GridThemeProvider;
