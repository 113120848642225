import { setContext } from '@apollo/client/link/context';
import { ApiEndpoint } from 'config/graphql.config';

/**
 * This link will enable setting the URI based on a promise
 */

export const uriLink = setContext(async () => {
  const determineUri = () => {
    const host = window.location.host;

    if (host.includes('kahuna-src')) {
      return ApiEndpoint.DEVELOPMENT;
    }

    if (host.includes('.acc.')) {
      return ApiEndpoint.ACCEPTANCE;
    }

    if (host.includes('.prv.')) {
      return ApiEndpoint.PREVIEW;
    }

    return ApiEndpoint.PRODUCTION;
  };

  return { uri: determineUri() };
});
