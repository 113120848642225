import React from 'react';
import { Modal } from 'components/Modal/Modal';
import { useTranslations } from 'hooks/useTranslations';
import { trackError } from 'utils/ddm';
import { CORPORATEMIDCOLORED_WARNINGMIDCOLORED } from '@vodafoneziggo/sandwich';
import { ErrorBoundaryProps, ErrorHandler, ErrorHandlingComponent, ErrorState } from './errorBoundary.types';

const Catch = (
  Component: ErrorHandlingComponent,
  errorHandler?: ErrorHandler
): React.ComponentType<ErrorBoundaryProps> => {
  return class ErrorBoundaryClass extends React.Component<ErrorBoundaryProps, ErrorState> {
    state: ErrorState = {
      error: undefined,
    };

    static getDerivedStateFromError(error: Error) {
      return { error };
    }

    /**
     * We need class implementation because componentDidCatch
     * does not exist in functional style
     */
    componentDidCatch(error: Error, info: React.ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info);
      }

      trackError(400, error.message);
    }

    render() {
      const { error } = this.state;
      const { isModal, children } = this.props;

      return (
        <Component error={error} isModal={isModal}>
          {children}
        </Component>
      );
    }
  };
};

const ErrorBoundary = Catch(({ error, isModal = false, ...props }) => {
  const { translate, translateHtml } = useTranslations('component.errorBoundary');

  if (error) {
    if (!isModal) {
      return (
        <div>
          <h2>{translate('title')}</h2>
          <h4>{translateHtml('message')}</h4>
          <h4>{error.message}</h4>
        </div>
      );
    }

    return (
      <Modal
        hideCloseButton
        isVisible
        headerTitle={translate('headerTitle')}
        headerIcon={CORPORATEMIDCOLORED_WARNINGMIDCOLORED}
        title={translate('title')}
        subTitle={translateHtml('message')}>
        {error.message}
      </Modal>
    );
  }

  return <>{props.children}</>;
});

export default ErrorBoundary;
