import { IEnterpriseContext, IUser } from '@vodafoneziggo/sandwich/models';
import { PaymentStatusCode } from 'types.generated';

const mapErrorStatusToLabel = (status: number) => {
  switch (status) {
    case 0:
      return '0 - timeout';
    case 400:
      return '400 - logic error';
    case 401:
      return '401 - authorization error';
    case 404:
      return '404 - not found error';
    case 415:
      return '415 - incorrect request url';
    case 500:
      return '500 - system error';
    case 502:
      return '502 - proxy error';
    case 503:
      return '503 - server offline';
  }

  return 'unknown';
};

export const trackError = (status: number, label: string) => {
  if (typeof window._ddm?.trigger !== 'function') return;

  window._ddm.trigger('error.myvfz', {
    data: {
      ga_data: {
        category: 'error.myvfz',
        action: mapErrorStatusToLabel(status),
        label,
      },
    },
  });
};

export enum NotificationSection {
  MANAGE = 'manage',
  USAGE_LIMIT = 'usagelimit',
}

export enum NotificationAction {
  CHANGE_NUMBER = 'change number',
  TRANSFER_NUMBER = 'transfer number',
  STOP_NUMBER = 'stop number',
  DATA = 'data',
  MINUTES = 'minutes',
  COSTS = 'costs',
}

/**
 * Utility function to make it easy to track notifications which appear after a flow has been succeeded.
 *
 * @param section - the section of the website, e.g. manage
 * @param action - the action that corresponds to the flow, e.g. change number
 * @param successful - whether or not the flow was completed succesfully
 */
export const trackNotification = (section: NotificationSection, action: NotificationAction, successful: boolean) => {
  if (typeof window._ddm?.trigger !== 'function') return;

  window._ddm.trigger('notification.myvfz', {
    data: {
      ga_data: {
        category: 'notification.myvfz',
        action: `${section} - ${action}`,
        label: successful ? 'succesful' : 'unsuccesful',
      },
    },
  });
};

export const trackRouteChange = (pathname: string, user: IUser, activeContext: IEnterpriseContext) => {
  if (typeof window._ddm?.trigger !== 'function') {
    return;
  }

  const path = pathname.replace(/([^\\/])$/, '$1/');

  const data = {
    page: {
      logged_in: true,
      type: 'myvfz',
      path,
    },
    user: {
      contact_id: user.contact.contact_id,
      account_type: 'enterprise',
      // 'consent-level': vm.consent_level,
      gender: user.contact.gender,
      'first-name': user.contact.first_name,
      'last-name': user.contact.last_name,
      'hashed-email': user.contact.hashed_email,
      'hashed-primary_phone': user.contact.hashed_primary_phone,
      user_type: (user as any).type,
      'context-role': (user as any).context_role,
      portal_permissions: (user as any).portal_permissions,
      nr_of_connections: (activeContext as any).nr_of_products,
      nr_of_billing_customers: user.enterprise.nr_billing_customers,
      has_multiple_accounts: user.enterprise.nr_accounts && user.enterprise.nr_accounts > 1,
      is_consumer_user: user.is_consumer,
      billing_arrangement: {
        id: activeContext.hashed_id,
        type: activeContext.type,
      },
      enterprise_account_type_extended: {
        is_iwr: activeContext.access_settings.is_iwr,
        is_iwr_ccm: activeContext.access_settings.is_iwr_ccm,
        is_ot: activeContext.access_settings.is_ot,
        is_gtf: activeContext.access_settings.is_gtf,
      },
    },
  };

  window._ddm.trigger('routeChange.ready', { data });
};

/**
 * Track a payment result
 *
 * @param statusCode
 */
export const trackPaymentResult = (statusCode: PaymentStatusCode) => {
  if (typeof window._ddm?.trigger !== 'function') return;

  window._ddm.trigger('invoice.myvfz', {
    data: {
      ga_data: {
        category: 'invoice.myvfz',
        action: 'paymentstatuscode',
        label: statusCode,
      },
    },
  });
};

/**
 *
 * @param {string} prevBundle - the name of the previous bundle
 * @param {string} updatedBundle - the name of the updated bundle
 */
export const trackGroupBundlesChange = (updatedBundle: string) => {
  if (typeof window._ddm?.trigger !== 'function') return;

  window._ddm.trigger('groupbundle.myvfz', {
    category: 'groupbundle.myvfz',
    action: 'bundle change - success',
    label: updatedBundle,
  });
};
