import nl from 'i18n/nl.json';

export const i18nConfig = {
  resources: {
    nl: {
      translation: nl,
    },
  },
  lng: 'nl',
  fallbackLng: 'nl',
  interpolation: {
    escapeValue: true,
  },
};
