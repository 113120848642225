import React, { useState, useEffect, useRef, createContext } from 'react';
import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AnyObject } from 'types/helpers';
import { IGraphQLContextState, GraphQLProviderProps, GraphQLClient, Exception } from './graphqlContext.types';
import { ClientError } from './components/ClientError';
import { authLink } from './links/auth.link';
import { uriLink } from './links/uri.link';
import { errorLink } from './links/error.link';
import { networkErrorLinks } from './links/networkError.link';

/**
 * We are not using the Apollo clients cache
 */
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const GraphQLContext = createContext<IGraphQLContextState>({ setHeader: () => {}, unsetHeader: () => {} });

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  const [exception, setException] = useState<Exception>();
  const [isItemValidationError, setIsItemValidationError] = useState(false);
  const [graphQLClient, setGraphQLClient] = useState<GraphQLClient>();
  const additionalHeaders = useRef<AnyObject>({});

  const state: IGraphQLContextState = {
    setHeader: (name: string, value: string) => {
      additionalHeaders.current[name] = value;
    },
    unsetHeader: (name: string) => {
      delete additionalHeaders.current[name];
    },
  };

  useEffect(() => {
    /**
     * This Link will add additional headers to the request
     */
    const headersLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...additionalHeaders.current,
        },
      };
    });

    const client = new ApolloClient({
      link: ApolloLink.from([
        ...networkErrorLinks(setException, 2),
        errorLink(setException),
        uriLink,
        authLink,
        headersLink,
        createHttpLink(),
      ]),
      cache: new InMemoryCache(),
      defaultOptions,
    });

    setGraphQLClient(client);
  }, []);

  // check if there is a validation error
  // in case of validation error, we don't want to show the error modal
  useEffect(() => {
    if (exception && exception.itemValidationErrors?.length) {
      setIsItemValidationError(true);
    }
  }, [exception]);

  return (
    <GraphQLContext.Provider value={state}>
      {graphQLClient && (
        <ApolloProvider client={graphQLClient}>
          {children}
          {exception && !isItemValidationError && (
            <ClientError exception={exception} onClose={() => setException(undefined)} />
          )}
        </ApolloProvider>
      )}
    </GraphQLContext.Provider>
  );
};
