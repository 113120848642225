interface IntlIdAndValues {
  id: string;
  values: { [key: string]: string | number } | undefined;
}

const isStringOrNumber = (value: any): boolean => {
  return typeof value === 'string' || typeof value === 'number';
};

/**
 * Filter the ID and values array from a rest parameter.
 *
 * @param { idsAndValues }
 * @returns { IntlIdAndValues }
 */
export const getIdAndValues = (idsAndValues: any[]): IntlIdAndValues => {
  const id = idsAndValues.filter((value) => isStringOrNumber(value)).join('.');
  const last = idsAndValues.slice(-1).pop();
  const values = last === Object(last) ? last : undefined;

  return {
    id,
    values,
  };
};
