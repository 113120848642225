import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { createBrowserHistory } from 'history';
import i18n from 'i18next';
import * as Sentry from '@sentry/react';

import { sentryConfig } from 'config/sentry.config';
import { i18nConfig } from 'config/i18n.config';
import App from 'components/App/App';

if (process.env.NODE_ENV === 'production') {
  const history = createBrowserHistory();

  Sentry.init(sentryConfig(history));
}

i18n.use(initReactI18next).init(i18nConfig);

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
