import React, { useContext } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { Breakpoint, CORPORATE_ARROWLEFT, Icon, MY_LOCK, MY_VFLOGO } from '@vodafoneziggo/sandwich';
import { Hide } from 'components/Hide/Hide';
import { FrontendConfigsContainer } from 'containers/Other/FrontendConfigs.container';
import { useContainer } from 'unstated-next';
import { Link } from 'react-router-dom';
import { LayoutContext } from 'context/layout/layout.context';
import { useTranslations } from 'hooks/useTranslations';
import { useUrlBase } from 'hooks/useUrlBase';
import * as S from './Header.styles';
import { ContextSwitcher } from './components/ContextSwitcher/ContextSwitcher';
import { UserInfo } from './components/UserInfo/UserInfo';
import { ShoppingCart } from './components/ShoppingCart/ShoppingCart';
import { SavedOrdersContainer } from './savedOrders.container';
import { MobileMenu } from './components/MobileMenu/MobileMenu';
import { MainMenu } from './components/MainMenu/MainMenu';
import { Submenu } from './components/Submenu/Submenu';
import { ContextSwitcherContainer } from './contextSwitcher.container';
import { useMenu } from './hooks/useMenu';

interface PaymentHeaderProps {
  isPartialPayment: boolean;
}

export const PaymentHeader = ({ isPartialPayment }: PaymentHeaderProps) => {
  const { translate } = useTranslations('component.paymentHeader');
  const urlBase = useUrlBase();

  return (
    <S.PaymentHeader>
      <Row justifyContent="between">
        <Col col={5} alignSelf="center">
          {isPartialPayment ? (
            <Link to={`${urlBase}/rekeningen/deels-betalen`}>
              <S.PaymentHeaderIcon icon={CORPORATE_ARROWLEFT} height="1.5rem" width="1.5rem" />
              <Hide down={Breakpoint.MD}>{translate('backPartialPayment')}</Hide>
            </Link>
          ) : (
            <Link to={`${urlBase}/rekeningen/overzicht`}>
              <S.PaymentHeaderIcon icon={CORPORATE_ARROWLEFT} height="1.5rem" width="1.5rem" />
              <Hide down={Breakpoint.MD}>{translate('back')}</Hide>
            </Link>
          )}
        </Col>
        <S.PaymentLogoCol col={2}>
          <Icon icon={MY_VFLOGO} height="3rem" width="3rem" />
        </S.PaymentLogoCol>
        <S.PaymentSecureCol col={5} alignSelf="center">
          <S.PaymentHeaderIcon icon={MY_LOCK} height="1rem" width="1rem" />
          <Hide down={Breakpoint.MD}>{translate('secure')}</Hide>
        </S.PaymentSecureCol>
      </Row>
    </S.PaymentHeader>
  );
};

export const Header = () => {
  const { features } = useContainer(FrontendConfigsContainer);
  const { state: layoutState } = useContext(LayoutContext);
  const { currentMenuItem } = useMenu();

  if (layoutState.isPaymentMode) return <PaymentHeader isPartialPayment={layoutState.isPartialPayment} />;

  return (
    <SavedOrdersContainer.Provider>
      <S.Header>
        <ContextSwitcherContainer.Provider>
          <S.WideContainer>
            <S.TopRow>
              <Hide up={Breakpoint.MD}>
                <S.MobileMenu>
                  <MobileMenu />
                </S.MobileMenu>
              </Hide>
              <UserInfo />
              <Hide up={Breakpoint.MD}>
                <ContextSwitcher />
              </Hide>
              {features?.hardwareOrdering && <ShoppingCart />}
            </S.TopRow>
            <S.MenuRow alignItems="center">
              <S.LogoCol col="auto">
                <S.LogoAnchor href="/zakelijk/my">
                  <Icon icon={MY_VFLOGO} height="3rem" width="3rem" />
                </S.LogoAnchor>
              </S.LogoCol>
              <S.MenuCol col>
                <MainMenu />
              </S.MenuCol>
              <Hide down={Breakpoint.MD}>
                <Col col="auto">
                  <ContextSwitcher />
                </Col>
              </Hide>
            </S.MenuRow>
          </S.WideContainer>
        </ContextSwitcherContainer.Provider>
      </S.Header>
      <S.SubHeader hasSubMenu={currentMenuItem && currentMenuItem.submenu?.length > 0}>
        <S.WideContainer>
          <Row>
            <Col>
              <Submenu />
            </Col>
          </Row>
        </S.WideContainer>
      </S.SubHeader>
    </SavedOrdersContainer.Provider>
  );
};
