import React, { useContext } from 'react';
import { BaseCSS } from 'styled-bootstrap-grid';
import { ScrollToTop } from '@vodafoneziggo/sandwich';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer/Footer';
import { LayoutContext } from 'context/layout/layout.context';
import * as S from './Baselayout.styles';

interface Props {
  children: React.ReactNode;
}

const BaseLayout: React.FC<Props> = ({ children }) => {
  const { state: layoutState } = useContext(LayoutContext);

  return (
    <S.Layout>
      <BaseCSS />
      {/* Component makes sure that on every route change window goes
     back to top (without animation) */}
      <ScrollToTop />
      <Header />
      <S.Wrapper>{children}</S.Wrapper>
      {!layoutState.isPaymentMode && <Footer />}
    </S.Layout>
  );
};

export default BaseLayout;
