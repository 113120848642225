import JSCookie from 'js-cookie';
import { AnyObject } from 'types/helpers';

const cookiePath = '/zakelijk/my/v2';

type StorageType = 'localStorage' | 'sessionStorage' | 'cookie';

/**
 * Check if given storage type is supported.
 * @param storageType
 * @returns {Boolean}
 */
function storageTypeIsSupported(storageType: StorageType) {
  try {
    window[storageType].x = 1;
    window[storageType].removeItem('x');

    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Check if localStorage is supported.
 * @returns {Boolean}
 */
function localStorageIsSupported() {
  return storageTypeIsSupported('localStorage');
}

/**
 * Check if sessionStorage is supported.
 * @returns {Boolean}
 */
function sessionStorageIsSupported() {
  return storageTypeIsSupported('sessionStorage');
}

/**
 * Wrapper class around Session and Localstorage.
 * Especially useful for Safari Incognito, where both session and localstorage
 * throwh an error if you try to us it. This class then defaults to a Cookie.
 */
class Storage {
  storagePrefix: string;

  storageType: StorageType;

  useCookies: boolean;

  constructor(storageType: StorageType) {
    this.storagePrefix = '';
    this.storageType = storageType;
    // When storagetype is not supported, also use cookie storage
    this.useCookies = storageType === 'cookie' || !storageTypeIsSupported(this.storageType);
  }

  /**
   * Set storage prefix
   * @param {string} prefix
   */
  public setPrefix(prefix: string) {
    this.storagePrefix = prefix ? `${prefix}.` : '';
  }

  /**
   * Get storage prefix
   * @returns {string}
   */
  public getPrefix() {
    return this.storagePrefix;
  }

  /**
   * Get the prefixed storage key
   * @returns {string}
   */
  private getPrefixedStorageKey(key: string) {
    return `${this.storagePrefix}${key}`;
  }

  /**
   * Set item
   * If you consider using ttl and want the browser to cleanup the values, make sure to use the Cookie storage,
   * because sessionStorage or localStorage are not cleaned up by the browser by date
   *
   * @param key Name of the property in storage
   * @param value The data to be stored
   * @param ttl Optional time to live in milliseconds
   */
  public set(key: string, value: string | AnyObject, ttl?: number) {
    let convertedValue = value;

    if (typeof convertedValue !== 'undefined' && convertedValue !== null) {
      // Default cookie expiration time (30 days)
      let expires: number | Date = 30;

      if (ttl) {
        expires = new Date(Date.now() + ttl);
        // It a ttl was provided, store the value in a nested object with an expiry timestamp
        convertedValue = { value: convertedValue, _expires: expires.getTime() };
      }

      if (typeof convertedValue === 'object') {
        convertedValue = JSON.stringify(convertedValue);
      }

      if (!this.useCookies) {
        window[this.storageType].setItem(this.getPrefixedStorageKey(key), convertedValue);
      } else {
        JSCookie.set(this.getPrefixedStorageKey(key), convertedValue, {
          // A cookie without expiration time, acts as a session cookie (destroyed when browser closes)
          expires: this.storageType === 'sessionStorage' ? undefined : expires,
          // Make sure we don't send the cookies as header on /rest requests
          path: cookiePath,
        });
      }
    }
  }

  /**
   * Get item
   * @param key Name of the property in storage.
   * @returns Value of key in storage.
   */
  public get<T = any>(key: string): T | undefined {
    let data = null;
    const storageKey = this.getPrefixedStorageKey(key);

    if (!this.useCookies) {
      data = window[this.storageType].getItem(storageKey);
    } else {
      data = JSCookie.get(storageKey);
    }

    try {
      const object = JSON.parse(data);

      if (object._expires) {
        // Expiry timestamp found
        if (object._expires < Date.now()) {
          // Item is expired
          this.remove(key);

          return undefined;
        }

        // Value is stored as nested property
        return object.value;
      }

      return object;
    } catch (e) {
      return data;
    }
  }

  /**
   * Remove item
   * @param key Name of the property in storage.
   */
  public remove(key: string) {
    const storageKey = this.getPrefixedStorageKey(key);

    if (!this.useCookies) {
      window[this.storageType].removeItem(storageKey);
    } else {
      JSCookie.remove(storageKey, {
        // Because we used the path to set the cookie, we also need to supply it when deleting
        path: cookiePath,
      });
    }
  }
}

const LocalStorage = new Storage('localStorage');
const SessionStorage = new Storage('sessionStorage');
const Cookie = new Storage('cookie');

export { localStorageIsSupported, sessionStorageIsSupported, LocalStorage, SessionStorage, Cookie };
