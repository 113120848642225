import React from 'react';
import { useContainer } from 'unstated-next';
import { useTranslations } from 'hooks/useTranslations';
import { OnSearchSubmitProps, SearchBar } from 'components/SearchBar/SearchBar';
import { ContextSwitcherContainer } from 'components/Header/contextSwitcher.container';

import { ContextSwitcherSearchProps } from './contextSwitcherSearch.types';
import * as S from './contextSwitcherSearch.styles';

export const ContextSwitcherSearch = ({ filterContexts }: ContextSwitcherSearchProps) => {
  const { translate } = useTranslations('component.contextSwitcher');
  const { setIsSearchInitialState } = useContainer(ContextSwitcherContainer);

  /**
   * Submit current search query.
   */
  const submitSearch = ({ searchTerm }: OnSearchSubmitProps) => {
    setIsSearchInitialState(false);
    filterContexts(searchTerm);
  };

  return (
    <S.Wrapper>
      <SearchBar onSearchSubmit={submitSearch} label={translate('searchPlaceholder')} testId="context-switcher" />
    </S.Wrapper>
  );
};
